import Vue from "vue";
import VueRouter from "vue-router";
import { isUserLoggedIn } from "@/auth/utils";
import { canNavigate } from "@/libs/acl/routeProtection";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    base: "/",
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [
        {
            path: "/",
            redirect: "/:prefix/order",
            name: "home"
        },
        {
            path: "/logout",
            name: "logout",
            component: () => import("@/views/pages/logout/logout.vue"),
            meta: {
                layout: "full",
                action: "read",
                resource: "basic"
            }
        },
        {
            path: "/:prefix/resource-loading",
            name: "resource-loading",
            component: () => import("@/views/pages/dashboard/ResourceLoading.vue"),
            meta: {
                resource: "pcp",
                breadcrumb: [
                    {
                        text: "Dashboards"
                    },
                    {
                        text: "Resource Loading",
                        active: true,
                        icon: "dashboard"
                    }
                ]
            }
        },
        {
            path: "/:prefix/schedule-analysis",
            name: "schedule-analysis",
            component: () => import("@/views/pages/dashboard/ScheduleAnalysis.vue"),
            meta: {
                resource: "pcp",
                breadcrumb: [
                    {
                        text: "Dashboards",
                        active: false
                    },
                    {
                        text: "Schedule Analysis",
                        active: true,
                        icon: "dashboard"
                    }
                ]
            }
        },
        {
            path: "/:prefix/oee",
            name: "oee",
            component: () => import("@/views/pages/dashboard/Oee.vue"),
            meta: {
                resource: "pcp",
                breadcrumb: [
                    {
                        text: "Dashboards",
                        active: false
                    },
                    {
                        text: "OEE",
                        active: true,
                        icon: "dashboard"
                    }
                ]
            }
        },
        {
            path: "/:prefix/order",
            name: "order-list",
            component: () => import("@/views/pages/order/OrderList.vue"),
            meta: {
                resource: "pcp",
                breadcrumb: [
                    {
                        text: "Orders",
                        active: true,
                        icon: "order"
                    }
                ]
            }
        },
        {
            path: "/:prefix/order-details/:operation_id",
            name: "order-details",
            component: () => import("@/views/pages/order/OrderDetails.vue"),
            meta: {
                resource: "pcp",
                breadcrumb: [
                    {
                        text: "Orders",
                        to: { name: "order-list" },
                        icon: "order"
                    },
                    {
                        text: "Informations",
                        active: true
                    }
                ]
            }
        },
        {
            path: "/:prefix/production-progress/:operation_id?",
            name: "production-progress",
            component: () => import("@/views/pages/production-progress/ProductionProgress.vue"),
            meta: {
                resource: "pcp",
                breadcrumb: [
                    {
                        text: "Production Progress",
                        active: true,
                        icon: "operation"
                    }
                ]
            }
        },
        {
            path: "/error-404",
            name: "error-404",
            component: () => import("@/views/error/Error404.vue"),
            meta: {
                layout: "full",
                action: "read",
                resource: "basic"
            }
        },
        {
            path: "/:prefix/not-authorized",
            name: "not-authorized",
            component: () => import("@/views/pages/miscellaneous/NotAuthorized.vue"),
            meta: {
                layout: "full",
                action: "read",
                resource: "basic"
            }
        },
        {
            path: "/:prefix/authenticate-required",
            name: "authenticate-required",
            component: () => import("@/views/pages/miscellaneous/AuthenticateRequired.vue"),
            meta: {
                layout: "full",
                action: "read",
                resource: "basic"
            }
        },
        {
            path: "/:prefix/account-setting",
            name: "account-setting",
            component: () => import("@/views/pages/account-setting/AccountSetting.vue"),
            meta: {
                resource: "operator",
                action: "read",
                breadcrumb: [
                    {
                        text: "Account Settings",
                        active: true,
                        icon: "profile"
                    }
                ]
            }
        },
        {
            path: "/:prefix/support",
            name: "support",
            component: () => import("@/views/pages/support/Support.vue"),
            meta: {
                resource: "basic",
                breadcrumb: [
                    {
                        text: "Support",
                        active: true,
                        icon: "profile"
                    }
                ]
            }
        },
        {
            path: "/:prefix/operator",
            name: "operator",
            component: () => import("@/views/pages/operator/Operator.vue"),
            meta: {
                resource: "operator",
                action: "read"
            }
        },
        {
            path: "/:prefix/operator/events/resources",
            name: "operator-events-resource",
            component: () => import("@/views/pages/operator/events/Resources.vue"),
            meta: {
                resource: "operator",
                action: "read"
            }
        },
        {
            path: "/:prefix/operator/orders",
            name: "operator-orders-all",
            component: () => import("@/views/pages/operator/Orders.vue"),
            meta: {
                resource: "operator",
                action: "read"
            }
        },
        {
            path: "/:prefix/operator/barcode",
            name: "operator-barcode",
            component: () => import("@/views/pages/operator/Barcode.vue"),
            meta: {
                resource: "operator",
                action: "read"
            }
        },
        {
            path: "/:prefix/operator/production-progress/:resource_id/:order_id/:return_orders/:multiple?/appointment_type=:appointment_type?",
            name: "operator-production-progress",
            component: () => import("@/views/pages/operator/ProductionProgress/index.vue"),
            meta: {
                resource: "operator",
                action: "read",
                breadcrumb: [
                    {
                        text: "Orders",
                        active: false,
                        to: { name: "operator" },
                        icon: "operator"
                    },
                    {
                        text: "Production Progress",
                        active: true,
                        icon: "operator"
                    }
                ]
            }
        },
        {
            path: "/:prefix/operator/production-progress-multiple/:resource_id/:multiple/:return_orders?/preactor_orders=:preactor_orders?/appointment_type=:appointment_type?",
            name: "operator-production-progress-multiple",
            component: () => import("@/views/pages/operator/ProductionProgress/index.vue"),
            meta: {
                resource: "operator",
                action: "read",
                breadcrumb: [
                    {
                        text: "Orders",
                        active: false,
                        to: { name: "operator" },
                        icon: "operator"
                    },
                    {
                        text: "Production Progress",
                        active: true,
                        icon: "operator"
                    }
                ]
            }
        },
        {
            path: "/:prefix/change-account",
            name: "change-account",
            component: () => import("@/views/pages/account-setting/ChangeAccount.vue"),
            meta: {
                resource: "basic",
                action: "read"
            }
        },
        {
            path: "*",
            redirect: "error-404"
        },
        {
            path: "/:prefix/date-time-picker",
            name: "date-time-picker",
            component: () => import("@/views/DateTimePickerView.vue")
        }
    ]
});

router.beforeEach((to, from, next) => {
    if (to?.name === "change-account") return next();

    if (
        to?.name !== "change-account" &&
        !!localStorage.getItem("userData") &&
        !localStorage.getItem("accessToken")
    ) {
        return next({
            name: "change-account",
            params: { prefix: localStorage.getItem("prefix") }
        });
    }

    let pcpAccess = null;
    if (JSON.parse(localStorage.getItem("userData"))) {
        pcpAccess =
            JSON.parse(localStorage.getItem("userData")).ability.find(
                (element) => element.action == "manage"
            ) ||
            JSON.parse(localStorage.getItem("userData")).ability.find(
                (element) => element.subject == "pcp"
            );
    }

    const isLoggedIn = isUserLoggedIn();

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        return next({ name: "home", params: { prefix: to.params.prefix } });
    }

    if (!canNavigate(to)) {
        // Redirect to operator mode
        if (!pcpAccess) {
            return next({
                name: "operator",
                params: { prefix: localStorage.getItem("prefix") }
            });
        }

        // If logged in => not authorized
        return next({
            name: "home",
            params: { prefix: localStorage.getItem("prefix") }
        });
    }

    return next();
});

router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById("loading-bg");
    if (appLoading) {
        appLoading.style.display = "none";
    }
});

export default router;
